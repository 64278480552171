
import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyChrist = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Thomas Christ</h2>
        <div className={styles.center}><p>The following was transcribed from a phone interview.</p></div>
        <section>
          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>1984-1985</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>The preliminary work I did with DPZ in Miami. As town architect, I received a lot of commissions very quickly and did many of the beginning houses on Tupelo Street and Ruskin, along with the Savannah Street Beach Pavilion. I also did some of the buildings in the work area, including a small tower on axis with Tupelo Street called “Poet’s Tower.”</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>Being able to work with a number of very good architects, including Andrés. Also doing one of the iconic beach walk-overs, the Savannah Street Beach Walkover, even though it is simple it is still something of which I am very proud.</p>

          <p className={styles.bold}>What were common challenges you found while working in Seaside?</p>
          <p>Working with clients, each one wanting something better than the next guy, made it difficult to do nice, simple architecture. Most architects working in Seaside were successful keeping their clients at bay so they could continue to create that nice, simple architecture.</p>

          <p>Designing houses using this new type of code and at the same time defining this southern vernacular style, but still designing in a reasonably sophisticated way was challenging. There were many examples of this northern Florida style in the surrounding areas, some great and some really horrible. In the early years individual architects began designing in their own style, creating hteir own interpretations of the vernacular. Some were more successful than others. In the third or fourth year at Seaside, architects began trying to push the envelope, push the code to see just how far they could go. There was an attitude that you couldn’t stray far from the intent of Andrés and Robert, but in some ways they both really wanted the code to be tested, to see what could be made out of it. There are some great examples of these forward-thinking ideas, for example the house by Victoria Casasco on Savannah Street and another dog-trot house that was also a push.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>It is interesting to remember the very early days of Seaside. We all had a lot of fun, architects, contractors, we were all working together. Experimenting with different ideas constantly, workmen and architects alike were excited to try something new. At the end of each day we would all get together at the beach to drink beers and play volleyball. It was a fun time, a good time. As the city began to grow things became more serious and everyone became more focused on the work aspect. Slowly that time went away.</p>

          <p>It was interesting to watch the town grow from a small town and simple little cottages to larger more complex houses. The whole attitude of the town changed. It is interesting to see how a town can change over time, when exposed to different influences. In Seaside, those influences were mostly economical.</p>

          <p className={styles.bold}>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>New, but I’m old.</p>

          <p>Our firm doesn’t do a lot of urbanism work, it’s primarily architectural. In the land planning we have done, we have used New Urbanist ideals. Generally though we do high-end residential and restaurants.</p>

          <p className={styles.bold}>Can you give a brief summary of what you are doing today, including any recent projects, awards, or positions?</p>
          <p>High-end residential projects, scheduled to be published in a couple of magazines this year.</p>
        <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyChrist
